import React from "react";
import {Button, Checkbox, Container, Form} from "semantic-ui-react";
import { v4 as uuidv4 } from 'uuid';

let jwt = require('jsonwebtoken');
class TokenJWSv3Pane extends React.Component {
    constructor(props) {
        super(props);
        // https://apidocs.ngtv.io/Token/Misc/jws/#version-3-latest Field information
        this.state = {
            jti: uuidv4(),
            mvpd: "",
            requestorId: "",
            streamUrlsClob: "",
            entitledNetworksClob:"",
            productsClob: "",
            secret: "",
            concurrencyLimit: "",
            concurrencySilo: "",
            kid: "",
            entitlementsClob: "",
            mediaIdsClob: "",
            network: "",
            skipGeoCheck: false
        };
    }

     isNumeric(str) {
        if (typeof str != "string") return false // we only process strings!
        return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
            !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
    }

    generateJWSv3 = () => {
        let userId = null;
        try {
            userId = this.props.user.sub || null;

            const {jti, network, kid, secret, mvpd, requestorId, concurrencySilo, streamUrlsClob, mediaIdsClob, entitledNetworksClob, entitlementsClob, productsClob} = this.state;

            if (!userId) {
                throw "No selected route and/or user can not be identified"
            }

            let ttl = parseInt(this.state.ttl);
            if (ttl > 86400) {
                const confirmation = confirm("You are about to create a Token with a really long expiration, this is very insecure and if leaked could adversely affect our brands.");
                if (confirmation !== true) {
                    return;
                }
            }
            if (ttl < 300 || !ttl) {
                ttl = 300;
            }

            if (jti.length < 1) {
                this.props.toast("JTI is a required field.");
                return;
            }

            if (network.length < 1) {
                this.props.toast("Network is a required field.");
                return;
            }

            if (kid.length < 1) {
                this.props.toast("KID is a required field.");
                return;
            }

            if (secret.length < 1) {
                this.props.toast("Private Key is a required field.");
                return;
            }

            const permissions = {
                skipGeoCheck: this.state.skipGeoCheck
            }

            const now = new Date().getTime();


            const body = {
                user: userId,
                jti: jti,
                ...mvpd.length > 0 && {mvpd: this.state.mvpd},
                ...requestorId.length > 0 && {requestor: this.state.requestorId},
                network,
                exp: parseInt(now / 1000 + ttl),
                ...this.isNumeric(this.state.concurrencyLimit) && {concurrencyLimit: parseInt(this.state.concurrencyLimit)},
                ...concurrencySilo.length > 0 && {concurrencySilo: this.state.concurrencySilo},
                ...streamUrlsClob.length > 0 && {urls: this.state.streamUrlsClob.split("\n")},
                ...mediaIdsClob.length > 0 && {media: this.state.mediaIdsClob.split("\n")},
                ...entitledNetworksClob.length > 0 && {entitledNetworks: this.state.entitledNetworksClob.split("\n")},
                ...entitlementsClob.length > 0 && {entitlements: this.state.entitlementsClob.split("\n")},
                ...productsClob.length > 0 && {products: this.state.productsClob.split("\n")},
                permissions,
            };

            const payload = jwt.sign(body, secret, {
                algorithm: "RS512",
                header: {
                    typ: "JWT",
                    ver: 3,
                    kid
                }
            });
            this.props.onFormSubmit(payload);

        } catch(error) {
            console.log(error);
            this.props.toast("Getting Payload failed")
        }
    };

    handleChange = id => event => {
        let text = event.target.value;
        this.setState({
            [id]: text
        })
    };

    handleToggle = id => event => {
        console.log(`State: `, this.state)
        this.setState({
            [id]: document.getElementById(`${id}Box`).checked
        })
    };

    render() {
        return (
            <Container fluid style={{maxHeight: "82vh", overflowY: "auto", overflowX: "hidden"}}>
                <Form>
                    <Form.Field>
                        <label>Generate JWS Token</label>
                    </Form.Field>
                    <Form.Field>
                        <label>JTI</label>
                        <input
                            type="text"
                            placeholder="*JTI"
                            onChange={(event, {value}) => this.setState({jti: value})}
                            defaultValue={this.state.jti}
                            required
                        />
                    </Form.Field>
                    <Form.Field>
                        <label>KID</label>
                        <input
                            type="text"
                            placeholder="*KID"
                            onChange={this.handleChange("kid")}
                            defaultValue={this.state.kid}
                            id="kidId"/>
                    </Form.Field>
                    <Form.Field>
                        <label>MVPD (e.g. turnernow, mss-me-core, etc.)</label>
                        <input
                            type="text"
                            placeholder="MVPD"
                            onChange={this.handleChange("mvpd")}
                            id="mvpdId"/>
                    </Form.Field>
                    <Form.Field>
                        <label>Requestor</label>
                        <input
                            type="text"
                            placeholder="Requestor"
                            onChange={this.handleChange("requestorId")}
                            id="requestorId"/>
                    </Form.Field>
                    <Form.Field>
                        <label>Network (e.g. WMC, TBS, MML, etc.)</label>
                        <input
                            type="text"
                            placeholder="*Network"
                            onChange={this.handleChange("network")}
                        />
                    </Form.Field>
                    <Form.Field>
                        <label>Private Key</label>
                        <textarea
                            placeholder="Secret"
                            onChange={this.handleChange("secret")}
                            id="secretInput"/>
                    </Form.Field>
                    <Form.Field>
                        <label>Stream URLs</label>
                        <textarea
                            placeholder="Stream Urls"
                            onChange={this.handleChange("streamUrlsClob")}
                            id="streamUrlsTextArea"/>
                    </Form.Field>
                    <Form.Field>
                        <label>Media IDs</label>
                        <textarea
                            placeholder="Media IDs"
                            onChange={this.handleChange("mediaIdsClob")}
                            id="mediaIdsTextArea"/>
                    </Form.Field>
                    <Form.Field>
                        <label>Entitlements</label>
                        <textarea
                            placeholder="Entitlements"
                            onChange={this.handleChange("entitlementsClob")}
                            id="entitlementsTextArea"/>
                    </Form.Field>
                    <Form.Field>
                        <label>Entitled Networks</label>
                        <textarea
                            placeholder="Entitled Networks"
                            onChange={this.handleChange("entitledNetworksClob")}
                            id="entitledNetworksTextArea"/>
                    </Form.Field>
                    <Form.Field>
                        <label>Products</label>
                        <textarea
                            placeholder="Products"
                            onChange={this.handleChange("productsClob")}
                            id="productsTextArea"/>
                    </Form.Field>
                    <Form.Field>
                        <label>Concurrency Limit</label>
                        <input
                            type="text"
                            placeholder="Concurrency Limit"
                            onChange={this.handleChange("concurrencyLimit")}
                            defaultValue={this.state.concurrencyLimit}
                            id="concurrencyLimitId"/>
                    </Form.Field>
                    <Form.Field>
                        <label>Concurrency Silo</label>
                        <input
                            type="text"
                            placeholder="Concurrency Silo"
                            onChange={this.handleChange("concurrencySilo")}
                            defaultValue={this.state.concurrencySilo}
                            id="concurrencySiloId"/>
                    </Form.Field>
                    <Form.Field>
                        <label>Skip Geo Check</label>
                        <Checkbox id="skipGeoCheckBox" toggle onClick={this.handleToggle('skipGeoCheck')} checked={this.state.skipGeoCheck} />
                    </Form.Field>
                    <Form.Field>
                        <label>TTL</label>
                        <select id="ttlDropDown" onChange={this.handleChange("ttl")}>
                            <option value='300'>5 Minutes</option>
                            <option value="3600">1 Hour</option>
                            <option value="28800">8 Hours</option>
                            <option value="86400">1 Day</option>
                            <option value="604800">7 Days</option>
                            <option value="2592000">30 Days</option>
                            <option value="7776000">90 Days</option>
                        </select>
                    </Form.Field>
                    <Button onClick={this.generateJWSv3}> Generate </Button>
                    <br/>
                </Form>
            </Container>
        );
    }
}

export default TokenJWSv3Pane
