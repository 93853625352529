import React from "react";
import {Form, TextArea} from "semantic-ui-react";
import ContingentButton from "../../ContingentButton";

const TOKEN_TYPES = {
    spe: "SPE Token",
    ngtv: "NGTV Token",
    turner: "Turner Token",
    isp: "ISP Token"
}

class TokenRefreshPane extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            originalToken: "",
            tokenType: "",
            renewedToken: "",
            ttl: 300,
            inputTokenType: "",
            tokenTypes: []
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (JSON.stringify(prevProps.userPermissions) !== JSON.stringify(this.props.userPermissions)) {
            const updatedTokenTypes = [];
            for (const permission of this.props.userPermissions) {
                if (permission.service === this.props.service && permission.module === this.props.module && permission.role === "editor") {
                    updatedTokenTypes.push(permission.scope);
                }
            }
            console.log("TokenRefreshPane.componentDidUpdate: updating permitted token types: ", updatedTokenTypes);
            this.setState({tokenTypes: updatedTokenTypes});
        }

        if (JSON.stringify(prevState.originalToken) !== JSON.stringify(this.state.originalToken)) {
            let updatedTokenType = "";
            if (this.state.originalToken.substr(0,3) === "exp") {
                // SPE Token
                updatedTokenType = "spe";
            } else if (this.state.originalToken.split(("|")).length > 2) {
                // NGTV Token
                updatedTokenType = "ngtv";
            } else if (this.state.originalToken.split(".").length === 3) {
                // Turner token
                updatedTokenType = "turner";
            } else if (this.state.originalToken.split(".").length === 4) {
                // ISP Token
                updatedTokenType = "isp";
            } else {
                updatedTokenType = "";
            }
            console.log("TokenRefreshPane.componentDidUpdate: updating type of token input: ", updatedTokenType);
            this.setState({tokenType: updatedTokenType});
        }
    }

    refreshToken = () => {
        let userId = null;
        try{
            console.log("refreshToken Started...")
            userId = this.props.user.sub|| null;

            if (!userId) {
                throw "user can not be identified";
            }

            if (!this.state.tokenType || !Object.keys(TOKEN_TYPES).includes(this.state.tokenType)) {
                this.props.toast("Unknown token type, not refreshing. Please try again");
                return;
            }

            if (!this.state.originalToken) {
                this.props.toast("Please provide a token");
                return;
            }

            let ttl = parseInt(this.state.ttl);
            if (ttl > 86400) {
                const confirmation = confirm("You are about to create a Token with a really long expiration, this is very insecure and if leaked could adversely affect our brands.");
                if (confirmation !== true){
                    return;
                }
            }
            if (ttl < 300 || !ttl) {
                ttl = 300;
            }

            const body = {
                token: this.state.originalToken,
                userId: userId,
                ttl: ttl,
                type: this.state.tokenType
            };

            this.props.toast(`Refreshing ${TOKEN_TYPES[this.state.tokenType]}`);

            console.log(body);

            this.props.TokenAdminDataProvider.renewToken(body).then(resp => {
                resp.json().then(payload => {
                    this.props.onFormSubmit(payload);
                }).catch(error =>{
                    this.props.toast(error)
                });
            });
        } catch(error) {
            console.log(error);
            this.props.toast("Getting Payload failed")
        }
    };

    handleChange = id => event => {
        let text = event.target.value;
        this.setState({
            [id]: text
        })
    };


    render() {
        return (<Form>
            <Form.Field>
                <label>Renew Tokens</label>
            </Form.Field>
            <Form.Field>
                <label>Token</label>
                <TextArea
                    required
                    placeholder="Accepts ISP, SPE, NGTV, and Turner tokens."
                    onChange={(event, {value}) => this.setState({originalToken: value})}
                />
            </Form.Field>
            <Form.Field>
                <label>TTL</label>
                <select id="ttlDropDown" onChange={this.handleChange("ttl")}>
                    <option value='300'>5 Minutes</option>
                    <option value="3600">1 Hour</option>
                    <option value="28800">8 Hours</option>
                    <option value="86400">1 Day</option>
                    <option value="604800">7 Days</option>
                    <option value="2592000">30 Days</option>
                    <option value="7776000">90 Days</option>
                </select>
            </Form.Field>
            <ContingentButton
                onClick={this.refreshToken}
                allPermissions={this.props.permissions}
                module={this.props.module}
                scope={this.state.tokenType}
                service={this.props.service}
                user={this.props.user}
            >Refresh</ContingentButton>
            <br/>
        </Form>)
    }


}

export default TokenRefreshPane
