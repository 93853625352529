import React, {Component} from 'react';
import {Button} from "semantic-ui-react";

class TokenInfoViewer extends Component {
    executeCopy = () => {
        var copyhelper = document.createElement("input");
        copyhelper.className = 'copyhelper';
        document.body.appendChild(copyhelper);
        const json = this.props.message;

        copyhelper.value = json.hasOwnProperty("token") ? json.token.toString().replace(/"/g, ""): JSON.stringify(json).toString().replace(/"/g, "");
        copyhelper.select();
        document.execCommand("copy");
        document.body.removeChild(copyhelper);
        this.props.toast('Success', 'Token copied to clipboard', 'success')
    };

    render() {
        let ingestJson = this.props.message;
        let displayJsonObj = {};
        if(ingestJson) {
            displayJsonObj = ingestJson;
        }
        return (
            <div className="json-block-light">
                <div style={divStyle}>
                <Button onClick={this.executeCopy} >Copy</Button>
                </div>
                <pre dangerouslySetInnerHTML={{__html: syntaxHighlight(JSON.stringify(displayJsonObj, null, 2))}}/>
            </div>
        );
    }
}

function syntaxHighlight(json) {
    json = json.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;');
    return json.replace(/("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?)/g, function (match) {
        var cls = 'number';
        if (/^"/.test(match)) {
            if (/:$/.test(match)) {
                cls = 'key';
            } else {
                cls = 'string';
            }
        } else if (/true|false/.test(match)) {
            cls = 'boolean';
        } else if (/null/.test(match)) {
            cls = 'null';
        }
        return '<span class="' + cls + '">' + match + '</span>';
    });
}

const divStyle = {
    'paddingBottom': '20px'
};

export default TokenInfoViewer;
