import React from "react";
import {Button, Form} from "semantic-ui-react";
import {JsonEditor as Editor} from 'jsoneditor-react';
import "jsoneditor/dist/jsoneditor.css";


class TokenISPViewPane extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            "action": "view",
            "type": "isp",
            "userId": "USER-ID",                     // Required. User that initiated this request.
            "token": "TOKEN-STRING"                  // Required. Encrypted ISP token string.
        };
    }

    componentWillUnmount() {
        console.log(`(componentWillUnmount) Started.`);
        if (this.jsonEditor) {
            this.jsonEditor.destroy;
        }
    }

    componentDidMount() {
        const options = {
        };
        this.jsonEditor = new Editor(this.container, options);
    }

    viewISPToken = () => {
        let userId = null;
        try{
            userId = this.props.user.sub || null;

            if(!userId ) {
                throw "No selected route and/or user can not be identified"
            }

            const body = {
                "userId": userId,
                "token": this.state.token
            };

            this.props.TokenAdminDataProvider.viewISPToken(body)
                .then(resp => {
                    resp.json().then(payload => {
                        this.props.onFormSubmit(payload);
                    }).catch(error =>{
                        this.props.toast("Getting Payload failed");
                        console.log(error);
                    });
                });
        } catch(error) {
            console.log(error);
            this.props.toast("Getting Payload failed")
        }
    };

    handleChange = id => event => {
        let text = event.target.value;
        this.setState({
            [id]: text
        });
    };

    render() {
        return (
            <Form>
                <Form.Field>
                    <label>View ISP Token</label>
                </Form.Field>
                <Form.Field>
                    <label>ISP Token</label>
                    <textarea
                        placeholder="ISP Token"
                        onChange={this.handleChange("token")}
                        id="ispTokenTextArea"/>
                </Form.Field>
                <Button onClick={this.viewISPToken}> View </Button>
                <br/>
            </Form>
        );
    }
}

export default TokenISPViewPane;
