import React from "react";
import {Container, Divider, Form, Input, Select, TextArea} from "semantic-ui-react";
import TokenAdminDataProvider from "../../../Services/TokenAdminDataProvider";
import ContingentButton from "../../ContingentButton";

class TokenTurnerPane extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            entitledNetworksText: "",
            entitlementsText: "",
            productsText: "",
            concurrencyLimit: "",
            concurrencySilo: "",
            mediaId: "",
            mvpd: "",
            path: "",
            requestorId: "",
            resourceId: "",
            sanction: "",
            userId: "",
            ttl: 300
        };
    }

    componentDidMount() {
        console.log(this.props.user);
        this.setState({userId: this.props.user.sub}, () => {
            console.log("set userId to ", this.state.userId);
        });
    }

    generateTurnerToken = () => {
        if (!this.state.mediaId && !this.state.path) {
            this.props.toast("Error", "Please enter either a media ID or a path to generate a Turner Token.", "error");
            return;
        }

        if (!this.state.requestorId) {
            this.props.toast("Error", "Please enter a requestor ID to generate a Turner Token.", "error");
            return;
        }

        if (!this.state.resourceId) {
            this.props.toast("Error", "Please enter a resource ID to generate a Turner Token.", "error");
            return;
        }

        if (!this.state.sanction) {
            this.props.toast("Error", "Please enter a sanction to generate a Turner Token.", "error");
            return;
        }

        if (!this.state.userId) {
            this.props.toast("Error", "Please enter a user ID to generate a Turner Token.", "error");
            return;
        }

        try {
            console.log("generateTurnerToken Started...")

            let {ttl} = this.state;
            if (ttl > 86400) {
                const confirmation = confirm("You are about to create a Token with a really long expiration, this is very insecure and if leaked could adversely affect our brands.");
                if (confirmation !== true) {
                    return;
                }
            }
            if (ttl < 300 || !ttl) {
                ttl = 300;
            }

            const body = {
                ...this.state.mediaId.length > 0 && {mediaId: this.state.mediaId},
                ...this.state.mvpd.length > 0 && {mvpd: this.state.mvpd},
                ...this.state.path.length > 0 && {path: this.state.path},
                ...this.state.requestorId.length > 0 && {requestorId: this.state.requestorId},
                ...this.state.resourceId.length > 0 && {resourceId: this.state.resourceId},
                ...this.state.sanction.length > 0 && {sanction: this.state.sanction},
                ...this.state.userId.length > 0 && {userId: this.state.userId},
                ...this.state.concurrencyLimit.length > 0 && {concurrencyLimit: this.state.concurrencyLimit},
                ...this.state.concurrencySilo.length > 0 && {concurrencySilo: this.state.concurrencySilo},
                ...this.state.entitlementsText.length > 0 && {entitlements: this.state.entitlementsText.split("\n")},
                ...this.state.entitledNetworksText.length > 0 && {entitledNetworks: this.state.entitledNetworksText.split("\n")},
                ...this.state.productsText.length > 0 && {products: this.state.productsText.split("\n")},
                ...ttl && {ttl: ttl}
            };

            this.props.TokenAdminDataProvider.getTurnerToken(body)
                .then(resp => {
                    resp.json().then(payload => {
                        this.props.onFormSubmit(payload);
                    }).catch(error =>{
                        this.props.toast(error)
                    });
                });
        } catch (error) {
            console.log(error);
            this.props.toast("Getting Payload failed")
        }
    };

    render() {
        return (
            <Container fluid style={{maxHeight: "82vh", overflowY: "auto", overflowX: "hidden"}}>
                <Form>
                    <Form.Field>
                        <label>Generate Turner Token</label>
                    </Form.Field>
                    <Divider content="Required Fields" horizontal section />
                    <Form.Group widths="equal" style={{position: "relative"}}>
                        <Form.Field
                            label="Media ID"
                            control={Input}
                            value={this.state.mediaId}
                            onChange={(event, {value}) => this.setState({mediaId: value})}
                        />
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <Divider vertical content="and/or" fitted />
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <Form.Field
                            label="Path"
                            control={Input}
                            value={this.state.path}
                            onChange={(event, {value}) => this.setState({path: value})}
                        />
                    </Form.Group>
                    <Form.Field
                        required
                        control={Input}
                        label="Requestor ID"
                        value={this.state.requestorId}
                        onChange={(event, {value}) => this.setState({requestorId: value})}
                    />
                    <Form.Field
                        required
                        control={Input}
                        label="Resource ID"
                        value={this.state.resourceId}
                        onChange={(event, {value}) => this.setState({resourceId: value})}
                    />
                    <Form.Field
                        required
                        control={Input}
                        label="Sanction"
                        value={this.state.sanction}
                        onChange={(event, {value}) => this.setState({sanction: value})}
                    />
                    <Form.Field
                        required
                        control={Input}
                        label="User ID"
                        value={this.state.userId}
                        onChange={(event, {value}) => this.setState({userId: value})}
                    />
                    <Form.Field
                        label="TTL"
                        control={Select}
                        options={[
                            {key: "5min", text: "5 Minutes", value: 300},
                            {key: "1hour", text: "1 Hour", value: 3600},
                            {key: "8hours", text: "8 Hours", value: 28800},
                            {key: "1day", text: "1 Day", value: 86400},
                            {key: "7days", text: "7 Days", value: 604800},
                            {key: "30days", text: "30 Days", value: 2592000},
                            {key: "90days", text: "90 Days", value: 7776000}
                        ]}
                        value={this.state.ttl}
                        onChange={(event, {value}) => this.setState({ttl: value})}
                    />
                    <Divider content="Optional Fields" horizontal section />
                    <Form.Field
                        control={Input}
                        label="Concurrency Limit"
                        value={this.state.concurrencyLimit}
                        onChange={(event, {value}) => this.setState({concurrencyLimit: value})}
                    />
                    <Form.Field
                        control={Input}
                        label="Concurrency Silo"
                        value={this.state.concurrencySilo}
                        onChange={(event, {value}) => this.setState({concurrencySilo: value})}
                    />
                    <Form.Field
                        control={TextArea}
                        label="Entitled Networks"
                        value={this.state.entitledNetworksText}
                        onChange={(event, {value}) => this.setState({entitledNetworksText: value})}
                        placeholder="Enter networks on separate lines"
                    />
                    <Form.Field
                        control={TextArea}
                        label="Entitlements"
                        value={this.state.entitlementsText}
                        onChange={(event, {value}) => this.setState({entitlementsText: value})}
                        placeholder="Enter entitlements on separate lines"
                    />
                    <Form.Field
                        control={Input}
                        label="MVPD"
                        value={this.state.mvpd}
                        onChange={(event, {value}) => this.setState({mvpd: value})}
                    />
                    <Form.Field
                        control={TextArea}
                        label="Products"
                        value={this.state.productsText}
                        placeholder="Enter products on separate lines"
                        onChange={(event, {value}) => this.setState({productsText: value})}
                    />
                    <ContingentButton
                        onClick={this.generateTurnerToken}
                        allPermissions={this.props.permissions}
                        module={this.props.module}
                        scope="turner"
                        service={this.props.service}
                        user={this.props.user}
                    > Generate </ContingentButton>
                </Form>
            </Container>
        );
    }
}

export default TokenTurnerPane;
