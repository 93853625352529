import React from "react";
import {Form, Input} from "semantic-ui-react";
import ContingentButton from "../../ContingentButton";

class TokenNGPane extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            mvpd: "",
            ttl: 300,
            userId: ""
        };
    }

    componentDidMount() {
        this.setState({userId: this.props.user.sub});
    }

    generateNgToken = () => {
        if (!this.state.mvpd) {
            this.props.toast("An mvpd value is required to generate an NG Token.");
            return;
        }

        if (!this.state.userId) {
            this.props.toast("A userId value is required to generate an NG Token.");
            return;
        }

        try {
            console.log("generateNgToken Started...")

            let ttl = parseInt(this.state.ttl);
            if (ttl > 86400) {
                const confirmation = confirm("You are about to create a Token with a really long expiration, this is very insecure and if leaked could adversely affect our brands.");
                if (confirmation !== true) {
                    return;
                }
            }
            if (ttl < 300 || !ttl) {
                ttl = 300;
            }

            const body = {
                mvpd: this.state.mvpd,
                userId: this.state.userId,
                ttl
            };

            this.props.TokenAdminDataProvider.getNGToken(body)
                .then(resp => {
                    resp.json().then(payload => {
                    this.props.onFormSubmit(payload);
                }).catch(error =>{
                    this.props.toast(error)
                });
            });
        } catch(error) {
            console.log(error);
            this.props.toast("Getting Payload failed")
        }
    };

    handleChange = id => event => {
        let text = event.target.value;
        this.setState({
            [id]: text
        });
    };

    render() {
        return (<Form>
            <Form.Field>
                <label>Generate NG Token</label>
            </Form.Field>
            <Form.Field
                label="MVPD"
                control={Input}
                required
                onChange={(event, {value}) => this.setState({mvpd: value})}
                value={this.state.mvpd}
            />
            <Form.Field
                label="User ID"
                control={Input}
                required
                onChange={(event, {value}) => this.setState({userId: value})}
                value={this.state.userId}
            />
            <Form.Field>
                <label>TTL</label>
                <select id="ttlDropDown" onChange={this.handleChange("ttl")}>
                    <option value='300'>5 Minutes</option>
                    <option value="3600">1 Hour</option>
                    <option value="28800">8 Hours</option>
                    <option value="86400">1 Day</option>
                    <option value="604800">7 Days</option>
                    <option value="2592000">30 Days</option>
                    <option value="7776000">90 Days</option>
                </select>
            </Form.Field>
            <ContingentButton
                onClick={this.generateNgToken}
                allPermissions={this.props.permissions}
                module={this.props.module}
                scope="ngtv"
                service={this.props.service}
                user={this.props.user}
            > Generate </ContingentButton>
            <br/>
        </Form>)
    }
}

export default TokenNGPane
