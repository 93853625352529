import React from "react";
import {Form} from "semantic-ui-react";
import ContingentButton from "../../ContingentButton";

class TokenSPEPane extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            "userId": "USER-ID",                     // Required. User that initiated this request.
            "path": "PATH-VALUE",                    // Required. No HTTP method or host
                                                     //   (ex: /hls/live/623466/br-live/master.m3u8).
            "profile": "PROFILE-VALUE",              // Optional. Identifies CDN profile for signing
                                                     //   CDN token. Default "spe".
            "ttl": "300"                             // Optional. TTL in seconds for generated token.
                                                     //   Default (5min) and maximum allowed configured
                                                     //   via metadata.
        };
    }

   generateSPEToken = () => {
        let userId = null;
        try {
            userId = this.props.user.sub || null;
            let path = this.state.path;

            if(!userId ) {
                this.props.toast("User can not be identified");
            }

            const ttl =  parseInt(this.state.ttl);
            if (ttl> 86400) {
                const confirmation = confirm("You are about to create a Token with a really long expiration, this is very insecure and if leaked could adversely affect our brands.");
                if (confirmation !== true) {
                    return;
                }
            }

            if (validUrl(path)) {
                let url = new URL(path);
                path = url.pathname;
            }

            const body = {
                "userId": userId,
                "path": path,
                "profile": this.state.profile,
                "ttl": ttl
            };

            console.log(body);
            this.props.TokenAdminDataProvider.getSPEToken(body)
                .then(resp => {
                    resp.json().then(payload => {
                        this.props.onFormSubmit(payload);
                    }).catch(error =>{
                        this.props.toast(error)
                    });
                });
        } catch(error) {
            console.log(error);
            this.props.toast("Error", error, "error");
        }
    };

    handleChange = id => event => {
        let text = event.target.value;
        this.setState({
            [id]: text
        });
    };

    render() {
        return(
            <Form>
                <Form.Field>
                    <label>Generate SPE Token</label>
                </Form.Field>
                <Form.Field>
                    <label>Path</label>
                    <input
                        type="text"
                        placeholder="Path Info"
                        onChange={this.handleChange("path")}
                        id="pathInput"
                    />
                </Form.Field>
                <Form.Field>
                    <label>Profile </label>
                    <select id="profileDropDown" onChange={this.handleChange("profile")}>
                        <option value='default'>default</option>
                        <option value='atomhls'>atomhls</option>
                        <option value="atomhds">atomhds</option>
                        <option value="tbsila_hls">tbsila_hls</option>
                        <option value="tbsila_live_hls">tbsila_live_hls</option>
                        <option value="mml">mml</option>
                        <option value="nbalp">nbalp</option>
                        <option value="catchsports">catchsports</option>
                        <option value="latamtve">latamtve</option>
                        <option value="latamtve_tsp_arg">latamtve_tsp_arg</option>
                    </select>
                </Form.Field>
                <Form.Field>
                    <label>TTL</label>
                    <select id="ttlDropDown" onChange={this.handleChange("ttl")}>
                        <option value='300'>5 Minutes</option>
                        <option value="3600">1 Hour</option>
                        <option value="28800">8 Hours</option>
                        <option value="86400">1 Day</option>
                        <option value="604800">7 Days</option>
                        <option value="2592000">30 Days</option>
                        <option value="7776000">90 Days</option>
                    </select>
                </Form.Field>
                <ContingentButton
                    onClick={this.generateSPEToken}
                    allPermissions={this.props.permissions}
                    module={this.props.module}
                    scope="spe"
                    service={this.props.service}
                    user={this.props.user}
                >Generate </ContingentButton>
                <br />
            </Form>
        );
    }
}

function validUrl(str) {
    let regexp = /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/;
    return regexp.test(str);
}

export default TokenSPEPane;
