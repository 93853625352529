import React from "react";
import {Button, Checkbox, Container, Form} from "semantic-ui-react";
import {JsonEditor as Editor} from 'jsoneditor-react';
import "jsoneditor/dist/jsoneditor.css";
import PasswordInput from "../../PasswordInput";
import { v4 as uuidv4 } from 'uuid';

let jwt = require('jsonwebtoken');
class TokenJWSPane extends React.Component {
    constructor(props) {
        super(props);
        // https://apidocs.ngtv.io/Token/Misc/jws/#version-2-latest Field information
        this.state = {
            "tokenId": uuidv4(),
            "requestorId": "",
            "streamUrlsClob": "",
            "entitledNetworksClob":"",
            "productsClob":"",
            "secret": "",
            "streamUrls": [],
            "mediaIdsClob": "",
            "entitlementsClob":"",
            "entitlements":[],
            "mediaIds": [],
            "resourceId": "",
            "skipGeoCheck": false
        };
    }


    componentWillUnmount() {
        console.log(`TokenJWSPane (componentWillUnmount) Started.`);
        if (this.jsonEditor) {
            this.jsonEditor.destroy
        }
    }

    componentDidMount() {
        const options = {
            mode: 'text'
        };
        this.state.jsonEditor = new Editor(this.container, options)
    }


    generateJWSv2 = () => {
        let userId = null;
        try{
            console.log(this);
            userId = this.props.user.sub || null;

            if(!userId ) {
                throw "No selected route and/or user can not be identified"
            }

            let ttl = parseInt(this.state.ttl);
            if(ttl> 86400) {
                const confirmation = confirm("You are about to create a Token with a really long expiration, this is very insecure and if leaked could adversely affect our brands.");
                if (confirmation !== true){
                    return;
                }
            }
            if (ttl<300 || !ttl){
                ttl = 300;
            }



            let tokenId = this.state.tokenId;
            if(!tokenId || !this.state.requestorId|| !this.state.resourceId) {
                this.props.toast("Token, Resource, and Requestor IDs are required fields!");
                return;
            }
            const now = new Date().getTime();
            const body = {
                "userId": userId,
                "tokenId": this.state.tokenId,
                "requestorId": this.state.requestorId,
                "resourceId": this.state.resourceId,
                "expiresAt": now +ttl*1000,
                "createdAt": now
            };

            if(this.state.streamUrlsClob){
                body["streamUrls"] = this.state.streamUrlsClob.split("\n");
            }

            if(this.state.mediaIdsClob){
                body["mediaIds"] = this.state.mediaIdsClob.split("\n");
            }

            if(this.state.entitledNetworksClob){
                body["entitledNetworks"] = this.state.entitledNetworksClob.split("\n");
            }

            if(this.state.productsClob){
                body["products"] = this.state.productsClob.split("\n");
            }

            if(this.state.entitlementsClob){
                body["entitlements"] = this.state.entitlementsClob.split("\n");
            }

            body['permissions'] = {"skipGeoCheck": this.state.skipGeoCheck};

            const payload = jwt.sign(body, this.state.secret+now, {
                algorithm: 'HS256',
                noTimestamp: true,
                header: {"typ": "JWS", "version": 2}
            });
            this.props.onFormSubmit(payload);

        } catch(error) {
            console.log(error);
            this.props.toast("Getting Payload failed")
        }

    };



    handleChange = id => event => {
        let text = event.target.value;
        this.setState({
            [id]: text
        })
    };

    handleJSONChange = id => event => {
        this.setState({
            [id]: event
        })
    };

    handleToggle = id => event => {
        console.log(`State: `, this.state)
        this.setState({
            [id]: document.getElementById(`${id}Box`).checked
        })
    };

    render() {
        return (
            <Container fluid style={{maxHeight: "82vh", overflowY: "auto", overflowX: "hidden"}}>
                <Form>
                    <Form.Field>
                        <label>Generate JWS Token</label>
                    </Form.Field>
                    <Form.Field>
                        <label>Token ID</label>
                        <input
                            type="text"
                            placeholder="*Token ID"
                            onChange={this.handleChange("tokenId")}
                            defaultValue={this.state.tokenId}
                            id="tokenId"/>
                    </Form.Field>
                    <Form.Field>
                        <label>Requestor ID (e.g. turnernow, mss-me-core, etc.)</label>
                        <input
                            type="text"
                            placeholder="*Requestor ID"
                            onChange={this.handleChange("requestorId")}
                            id="requestorId"/>
                    </Form.Field>
                    <Form.Field>
                        <label>Resource ID (e.g. WMC, TBS, MML, etc.)</label>
                        <input
                            type="text"
                            placeholder="*Resource ID"
                            onChange={this.handleChange("resourceId")}
                            id="resourceId"/>
                    </Form.Field>
                    <Form.Field>
                        <label>Secret</label>
                        <PasswordInput
                            value={this.state.secret}
                            placeholder="Secret"
                            onChange={this.handleChange("secret")}
                            id="secretInput"/>
                    </Form.Field>
                    <Form.Field>
                        <label>Stream URLs</label>
                        <textarea
                            placeholder="Stream Urls"
                            onChange={this.handleChange("streamUrlsClob")}
                            id="streamUrlsTextArea"/>
                    </Form.Field>
                    <Form.Field>
                        <label>Media IDs</label>
                        <textarea
                            placeholder="Media IDs"
                            onChange={this.handleChange("mediaIdsClob")}
                            id="mediaIdsTextArea"/>
                    </Form.Field>
                    <Form.Field>
                        <label>Entitled Networks</label>
                        <textarea
                            placeholder="Entitled Networks"
                            onChange={this.handleChange("entitledNetworksClob")}
                            id="entitledNetworksTextArea"/>
                    </Form.Field>
                    <Form.Field>
                        <label>Entitlements</label>
                        <textarea
                            placeholder="Entitlements"
                            onChange={this.handleChange("entitlementsClob")}
                            id="entitlementsTextArea"/>
                    </Form.Field>
                    <Form.Field>
                        <label>Products</label>
                        <textarea
                            placeholder="Products"
                            onChange={this.handleChange("productsClob")}
                            id="productsTextArea"/>
                    </Form.Field>
                    <Form.Field>
                        <label>Skip Geo Check</label>
                        <Checkbox id="skipGeoCheckBox" toggle onClick={this.handleToggle('skipGeoCheck')} checked={this.state.skipGeoCheck} />
                    </Form.Field>
                    <Form.Field>
                        <label>TTL</label>
                        <select id="ttlDropDown" onChange={this.handleChange("ttl")}>
                            <option value='300'>5 Minutes</option>
                            <option value="3600">1 Hour</option>
                            <option value="28800">8 Hours</option>
                            <option value="86400">1 Day</option>
                            <option value="604800">7 Days</option>
                            <option value="2592000">30 Days</option>
                            <option value="7776000">90 Days</option>
                        </select>
                    </Form.Field>
                    <Button onClick={this.generateJWSv2}> Generate </Button>
                    <br/>
                </Form>
            </Container>
        );
    }


}

export default TokenJWSPane
